import React, { useEffect, useState } from "react";
import "../css/addcart.css";
import Button from "./Button";
import { useHistory } from "react-router-dom";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  addCount,
  removeFromCart,
  decreaseCount,
  setInitialTotal,
} from "../Redux/features/cardSlice";
import Alert from "../components/Alert";
import {motion} from "framer-motion";

const AddCart = () => {
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const cardData = useSelector((state) => state.card.card);
  const count = useSelector((state) => state.card.count);
  const totalOffer = useSelector((state) => state.card.totalOffer);

  


  // const totalItemCount = cardItem.reduce((acc, item) => acc + item.count, 0);


  const dispatch = useDispatch();
  const handleRemoveFromCart = (product) => {
    Alert({title:"Product Removed",icon:"success"})
    dispatch(removeFromCart(product));
  };

  
  const handleAdd = (id) => {
    dispatch(addCount(id));
    // Alert({title:"Product Added to Cart",icon:"success"})
  };
  // const handleAdd = (index) => {
  //   dispatch(addCount(index));
  // };

  const handleRemove = (id) => {
    dispatch(decreaseCount(id));
    
  };
 

  return (
    <div className="mt-14 lg:px-24 md:px-16 px-3 ">
      <div className="bg-[#191919] md:py-10 md:px-2 p-4 rounded-md text-start addcard">
        <motion.h3 className="text-center text-white text-2xl font-medium uppercase "
        initial={{y:"2rem",opacity:0}}
        animate={{y:0,opacity:1}}
        transition={{
            duration:2,
             type:"spring"
        }}>
        
        
          Your cart
        </motion.h3>
        <Button
          style="bg-green-500 text-white font-normal intro-x"
          btnName="Back"
          onClick={handleBack}
        />
        <table className="w-[100%] mt-5">
          <thead >
            <tr>
               <th className="intro-x" >Product</th> 
              <th className="intro-x">Name</th>
              <th className="intro-x">Quantity</th>
              <th className="intro-x">Price</th> 
              {/* <th>Total</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody className="text-white text-center ">
            {cardData.map((item, index) => (
              <tr key={index}>
                {/* <td>{index}</td> */}
                <td>
                <span className="flex justify-center items-center">
                 
                  
                    <img
                      src={item.image}
                      className="w-[80px] rounded-md h-[80px] intro-x"
                      alt="product"
                    />
                   
                  
                </span>
                </td>
                <td className="intro-x" >{item.heading}</td>
                <td className="intro-x" >
                  <button className="p-2" onClick={()=>handleRemove(item.id)}>
                    -
                  </button>
                  {item.count}
                  <button className="p-2" onClick={() => handleAdd(item.id)}>
                    +
                  </button>
                </td>
                {/* <td>{item.offer}</td> */}
                {/* <td>{item.offer *count}</td> */}
                <td className="intro-x" >{item.totalOffer}</td>
              
                <td>
                  <button onClick={() => handleRemoveFromCart(item)}>
                    <IoCloseCircleSharp className="text-[25px]" />
                  </button>
                </td>
                {/* <td>dv</td> */}
              </tr>
            ))}
          </tbody>
        </table>
        <motion.span className="text-center block"  initial={{y:"2rem",opacity:0}}
        animate={{y:0,opacity:1}}
        transition={{
            duration:2,
             type:"spring"
        }}>
          {/* <button className="bg-blue-500  mt-4 text-white  font-normal">Total:  {totalOffer}</button> */}
           <Button
            style="bg-blue-500  mt-4 text-white  font-normal"
            btnName={`Total: ${totalOffer}`}
            //  onClick={()=>handleRemoveFromCart(item)}
          /> 
        </motion.span>
      </div>
    </div>
  );
};

export default AddCart;
